.section-document {
    .card {
        height: 100%;

        @media (max-width: 991px) {
            font-size: 14px;
        }
    }

    .row {
        @media (max-width: 991px) {
            --bs-gutter-y: 1rem;
        }
    }

    .row-signer {
        --bs-gutter-y: 0;

        + .row-signer {
            margin-top: 1rem;
        }
    }

    .image {
        @media (max-width: 991px) {
            background: none !important;
            border: none;
            height: auto !important;
            width: 100% !important;

            > img {
                display: none;
            }
        }

        @media (min-width: 992px) {
            background-color: #90B4FF;
            border-radius: 4px;
            position: relative;
        }

        .btn {
            width: 100%;

            @media (min-width: 992px) {
                bottom: 0.5rem;
                left: 0.5rem;
                position: absolute;
                right: 0.5rem;
                width: auto;
                z-index: 1;
            }
        }
    }
}

.section-signers {
    .btn-group {
        align-items: center;
        justify-content: flex-end;
    }

    .signer-title {
        @media (min-width: 992px) {
            margin-bottom: 0;
        }
    }
}

.section-expiration {
    .btn-group {
        margin-top: 1rem;

        @media (max-width: 991px) {
            flex-wrap: nowrap;
        }
    }

    @media (min-width: 992px) {
        .section-opening {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
    }
}

.activities-list {
    color: #707D88;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

    @media (max-width: 991px) {
        font-size: 13px;
        line-height: 1.35;
    }

    li {
        display: flex;
        padding-bottom: 8px;
        padding-top: 8px;

        @media (min-width: 992px) {
            align-items: center;
        }
    }

    .icon {
        flex: 0 0 auto;

        @media (min-width: 992px) {
            height: 16px;
            width: 16px;
        }
    }

    .activity {
        &-date {
            font-weight: 500;
        }

        &-success,
        &-danger {
            color: #384756;
        }

        &-success {
            .icon {
                color: #00832D;
            }
        }
    
        &-danger {
            .icon {
                color: #EA4335;
            }
        }
    }
}

.table-activities {
    color: #707D88;

    .icon {
        transform: translateY(-1px);
        vertical-align: middle;
    }

    .col-date {
        font-weight: 500;
    }

    .row-success,
    .row-danger {
        color: #384756;
    }

    .row-success {
        .icon,
        .col-date {
            color: #00832D;
        }
    }

    .row-danger {
        .icon,
        .col-date {
            color: #EA4335;
        }
    }
}

.form-prepare {
    .form-footer {
        margin-top: 1rem;
    }
}

.group-signers {
    .form-text {
        >span {
            cursor: pointer;
            position: relative;
        }
    }

    .icon-help {
        color: #246BFF;
    }
}

.page-document-sent {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    text-align: center;

    .icon-check {
        fill: #1BD185;
        margin-bottom: 24px;
    }

    @media (min-width: 768px) {
        .section {
            margin-left: auto;
            margin-right: auto;
            max-width: 544px;
        }
    }
}

.alert-terms {
    background-color: #fff;
    border-bottom: 1px solid #D5DFE8;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 3px 12px -2px rgba(85, 102, 119, 0.12);
    top: 0;
    color: #54626F;
    left: 0;
    padding-bottom: 32px;
    padding-top: 32px;
    position: absolute;
    width: 100%;

    .h3 {
        margin-bottom: 10px;
    }

    .form-check {
        margin-top: 26px;
    }

    .btn {
        margin-top: 34px;
        width: 100%;
    }

    @media (min-width: 992px) {
        border-radius: 0;

        >.container {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        .btn {
            margin-top: 0;
            width: auto;
        }
    }
}

.modal-signature {
    .form-text {
        display: none;
    }
}

.signature-list {
    margin-top: 20px;
}

.signature-item {
    border-radius: 5px;
    cursor: pointer;
    display: block;
    font-size: 18px;
    position: relative;

    .row {
        align-items: center;

        > div + div {
            margin-top: 2px;
        }
    }

    .form-check-input {
        margin-left: 0;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);

        &:checked {
            + .signature-item-body {
                background-color: #D8E5FF;
            }
        }
    }

    &-body {
        line-height: 1.33334;
        padding: 8px 16px;
        transition: background 0.15s ease;
    }

    &:hover {
        &-body {
            background-color: #D8E5FF;
        }
    }

    @media (min-width: 992px) {
        .row {
            > div + div {
                margin-top: 0;
            }
        }
    }
}

.signature-pad {
    background-color: #F9F9F9;
    border: 1px solid #D5DFE8;
    border-radius: 5px;
    margin-top: 1rem;
    position: relative;
    z-index: 0;

    > canvas {
        display: block;
    }

    &-wrapper {
        & + & {
            border-top: 1px solid #D5DFE8;
            margin-top: 5px;
            padding-top: 20px;
        }
    }

    &.read-only {
        pointer-events: none;

        &:after {
            bottom: 24px;
        }
    }

    &:after {
        background-color: #D5DFE8;
        bottom: 37px;
        content: "";
        height: 1px;
        left: 16px;
        position: absolute;
        right: 16px;
        z-index: -1;
    }
}

.fieldmarker {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 11'%3E%3Cpath fill='%232072B9' fill-rule='evenodd' d='M1 .13a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14.077a1 1 0 0 0 .714-.3l3.923-4a1 1 0 0 0 0-1.4l-3.923-4a1 1 0 0 0-.714-.3H1Z' clip-rule='evenodd'/%3E%3C/svg%3E");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    height: 34px;
    left: -80px;
    top: 0;
    position: absolute;
    width: 102px;

    &.hide {
        display: none;
    }
    
    @media (min-width: 992px) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 60 20'%3E%3Cpath fill='%232072B9' fill-rule='evenodd' d='M40.28 0H1a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h48.937a1 1 0 0 0 .705-.29l9.063-9a1 1 0 0 0 0-1.42l-9.063-9a1 1 0 0 0-.705-.29H40.28Z' clip-rule='evenodd' /%3E%3C/svg%3E");
    }
}

.document-page {
    box-shadow: 0px 8px 16px -4px rgba(85, 102, 119, 0.25);

    & + & {
        margin-top: 20px;
    }
}

.form-signing {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 544px;

    > .container {
        flex-grow: 1;
        padding-top: 48px;
        overflow-y: auto;
    }

    @media (min-width: 992px) {
        margin-top: 80px;
    }
}

.signer {
    &-header {
        color: #1C2C3C;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        line-height: 1.5;
        padding-left: 56px;
        position: relative;

        .avatar {
            left: 0;
            position: absolute;
        }

        &:hover {
            color: currentColor;
        }
    }
}