.canvas {
    &-wrapper {
        position: relative;

        .spinner {
            height: 2.5rem;
            left: 50%;
            position: absolute;
            top: 50%;
            margin-left: -1.25rem;
            margin-top: -1.25rem;
            width: 2.5rem;
        }
    }

    &-bg {
        user-select: none;
        pointer-events: none;
    }

    &-container {
        background-color: #fff;
        box-shadow: 0px 8px 16px -4px rgba(85, 102, 119, 0.25);
        border-radius: 1px;
        flex: 1 0 auto;
        overflow: hidden;
    
        canvas {
            touch-action: auto !important;
        }
    }

    &-page-number {
        --height: 38px;
        --font-size: 10px;

        align-items: center;
        color: #707D88;
        display: flex;
        font-size: calc(var(--font-size) / var(--canvas-scale));
        height: calc(var(--height) / var(--canvas-scale));
        justify-content: center;
        line-height: 1.35;
        text-align: center;
        transform: scale(calc(1 + var(--canvas-scale)));

        @media (min-width: 992px) {
            --height: 44px;
            --font-size: 8px;
        }
    }

    &-control {
        $self: &;

        border: 1px solid transparent;
        color: #1C2C3C;
        line-height: 1;
        outline: none;
        position: absolute;
        text-align: center;

        &[data-type=button] {
            cursor: pointer;
        }

        &::placeholder {
            color: #1C2C3C;
        }

        &:read-only {
            cursor: default;
        }

        &-signature,
        &-initial {
            color: transparent;

            &.completed {
                display: none;
            }
        }

        // &.completed {
        //     pointer-events: none;
        // }
    }

    &-img {
        display: block;
        pointer-events: none;
        position: absolute;
    }
}