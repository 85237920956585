.text-field{
    display: flex;
    flex-direction: column;
    gap: 4px;

    p{
        margin: 0;
    }

    &-title{
        font-weight: 500;
        font-size: 12px;
        line-height: 1.09;
        color: #142573;
        letter-spacing: -0.32px;
    }

    &-value{
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        color: #1C2C3C;
        letter-spacing: -0.32px;
    }
}