.dropdown {
    $self: &;

    display: inline-flex;
    position: relative;

    &-toggle {
        width: 100%;
    }

    &-menu {
        background-color: #fff;
        border: 1px solid #D5DFE8;
        border-radius: 5px;
        box-shadow: 0px 3px 12px -2px rgba(85, 102, 119, 0.12);
        display: none;
        list-style: none;
        margin: 4px 0 0;
        min-width: 216px;
        padding: 0.5rem;
        position: absolute;
        top: 100%;
        z-index: 1000;

        &-end {
            right: 0;
        }
    }

    &-item {
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        color: #384756;
        cursor: pointer;
        display: inline-flex;
        font-size: 13px;
        font-weight: 600;
        gap: 4px;
        justify-content: flex-start;
        line-height: 20px;
        position: relative;
        text-align: left;
        text-transform: uppercase;
        transition: background-color 0.15s ease, color 0.15s ease;
        white-space: nowrap;
        width: 100%;

        &,
        .dropdown-link {
            padding: 0.5rem;
        }

        .dropdown-link {
            color: currentColor;
            display: block;
        }

        > #{$self}-menu {
            margin-top: 0;
            margin-right: -1px;
            right: 100%;
            transform: translateY(-81px);
        }

        .icon {
            min-height: auto;
            vertical-align: bottom;
        }

        .spinner {
            height: 20px;
            width: 20px;
        }

        @at-root body:not(.is-mobile) #{&} {
            &.active,
            &:hover {
                background-color: #D8E5FF;
                color: #1452FF;

                .icon {
                    color: currentColor;
                }

                > #{$self}-menu {
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }

    &-title{
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: 4px 4px 0 0;
        color: #384756;
        display: inline-flex;
        font-size: 13px;
        font-weight: 600;
        gap: 4px;
        justify-content: flex-start;
        line-height: 20px;
        position: relative;
        text-align: left;
        text-transform: uppercase;
        transition: background-color 0.15s ease, color 0.15s ease;
        white-space: nowrap;
        width: 100%;
        border-bottom: 1px solid #D5DFE8;

        
        &,
        .dropdown-link {
            padding: 0.5rem;
        }
    }

    &.dropup > &-menu {
        bottom: 100%;
        margin-bottom: 4px;
        margin-top: 0;
        top: auto;
    }

    &.show > &-menu {
        display: flex;
        flex-wrap: wrap;
    }
}

.dropdown-signer {
    display: block;

    .avatar {
        font-size: 16px;
        flex: 0 0 32px;
        height: 32px;
        line-height: 28px;
        margin-right: 0.5rem;
        width: 32px;
    }

    .btn-signer-add {
        background-color: #fff;
        bottom: 0;
        border: none;
        padding: 18px 0.5rem;
        position: sticky;
        text-align: left;
        width: 100%;
        z-index: 1;
    }

    .dropdown {
        &-toggle {
            background-color: var(--action-bg-color);
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' fill='%238C97A0'/%3E%3C/svg%3E%0A");
            background-position: right 14px center;
            background-repeat: no-repeat;
            border-color: #D5DFE8;
            box-shadow: inset 0 0 0 4px #fff;
            font-size: 12px;
            height: 50px;
            padding: 8px 36px 8px 8px;
            position: relative;

            &:before {
                background: linear-gradient(270deg, var(--action-bg-color) 0%, rgba(234, 246, 241, 0) 100%);
                bottom: 8px;
                content: "";
                display: block;
                position: absolute;
                right: 36px;
                top: 8px;
                width: 16px;
            }

            &:focus {
                border-color: #246BFF;
            }
        }

        &-menu {
            max-height: 475px;
            padding: 0;
            width: 100%;
        }

        &-item {
            align-items: center;
            color: #384756;
            display: flex;
            position: relative;

            .btn-remove {
                color: #8C97A0;
                display: none;
                padding: 0 4px;
            }

            &.active,
            &:hover {
                background-color: #EBF1FF;
                color: #1C2C3C;
            }

            &:hover {
                .btn-remove {
                    display: block;
                }
            }
        }
    }

    .signer {
        &-item, &-item-title {
            align-items: center;
            background-color: transparent;
            display: flex;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.33334;
            overflow: hidden;
            padding: 0;
            text-align: left;
            text-transform: none;
            width: 100%;
        }

        &-info {
            flex-grow: 1;
        }

        &-email {
            color: #54626F;
            font-weight: normal;
            white-space: nowrap;
        }
    }
}

.dropdown-user {
    z-index: 1000;

    @media (max-width: 991px) {
        .avatar {
            font-size: 18px;
            height: 28px;
            line-height: 24px;
            width: 28px;
        }
    }

    .dropdown {
        &-toggle {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            height: 28px;
            justify-content: center;
            padding-right: 36px;
            position: relative;

            .avatar {
                right: 0;
                position: absolute;
                top: 0;
            }

            @media (min-width: 992px) {
                height: 40px;
                padding-right: 52px;
            }
        }

        &-menu {
            .icon {
                fill: #1452FF;
            }
        }
    }

    &-name {
        text-align: right;

        @media (max-width: 991px) {
            font-size: 10px;
            line-height: 14px;
        }
    }

    &-account {
        color: #707D88;
        font-size: 8px;
        font-weight: 400;
        line-height: 11px;
        text-align: right;

        @media (min-width: 992px) {
            font-size: 12px;
            line-height: 16px;
        }
    }
}